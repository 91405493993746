<template>
	<b-container fluid class="mx-auto px-0 mb-5">
		<div class="chat-container card2">
			<div class="header-chat">
				<div class="title">
					{{ $t("support.ticket_id") }}: {{ ID }} /
					{{ $t("support.subjects") }}:{{ Subject }}
				</div>
				<button class="back btn btn-aktrade" @click="hide()">
					<i class="fad fa-arrow-alt-circle-right"></i>
				</button>
			</div>
			<div class="content-chat">
				<template v-for="messages in Message">
					<div class="left-chat" :key="messages.ticket_Time" v-if="user.userId !== messages.ticket_User">
						<span>Time:{{ messages.ticket_Time }}</span>
						<span>{{ messages.ticket_Content }}</span>
					</div>
					<div class="right-chat" :key="'right' + messages.ticket_Time" v-else>
						<span>Time:{{ messages.ticket_Time }}</span>
						<span>{{ messages.ticket_Content }}</span>
					</div>
				</template>
			</div>
			<div class="send-chat">
				<input type="text" v-model.trim="message" class="custom-input" />
				<div class="button-send-group">
					<button class="send-button btn btn-aktrade" @click.prevent="onSendMessage()">
						<i class="fad fa-paper-plane"></i>
					</button>
				</div>
			</div>
		</div>
	</b-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	props: {
		ID: Number,
		Subject: String,
		SubjectID: Number,
	},
	data() {
		return {
			message: "",
		};
	},
	computed: {
		...mapGetters({
			Message: "ticket/DetailTicket",
			user: "userinfo/UserInfo",
		}),
	},
	methods: {
		hide() {
			this.$store.commit("core/HiddenChat");
		},
		getTicketDetail() {
			this.$store.dispatch("ticket/req_getTicketDetail", this.ID);
		},
		async onSendMessage() {
			if (!this.ID || !this.Subject) {
				this.$toastr.e(
					"Please Reload This Page and Get Detail Ticket Agian",
					"Opps. Somthing Wrong!"
				);
				return;
			}
			if (!this.message) {
				this.$toastr.e(
					"Please Enter Message Before Send",
					"Send Messang Failed"
				);

				return;
			}

			await this.$store.dispatch("ticket/req_postTicketMessage", {
				content: this.message,
				subject: this.SubjectID,
				replyID: this.ID,
			});
			this.message = '';

		},
	},
	mounted() {
		if (this.ID) {
			this.getTicketDetail();
		}
		this.$store.commit("core/RemoveUser");
	},
	created() {
		this.unsubscribe = this.$store.subscribe((mutation) => {
			switch (mutation.type) {
				case "ticket/SEND_MESSANGE_SUCCESS":
					this.getTicketDetail();
					break;
			}
		});
		// this.getTicketDetail();
	},
	beforeDestroy() {
		this.unsubscribe();
	},
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";
@import "~@/assets/scss/mixins.scss";
@import "~@/assets/scss/theme.scss";

.chat-container {
	width: 100%;
	height: 500px;
	background-color: #fff;
	// display: flex;
	flex-direction: column;

	.header-chat {
		height: 50px;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		background: #ffffff2e;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 20px;

		button.back {
			padding: 0;
			background: transparent;
			border-radius: 50%;
			width: 40px;
			height: 40px;
			font-size: 35px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-color: #fff;
			color: #fff;
		}
	}

	.content-chat::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		background-color: rgba(0, 0, 0, 0.3);
	}

	.content-chat::-webkit-scrollbar {
		width: 6px;
		background-color: rgba(0, 0, 0, 0.3);
	}

	.content-chat::-webkit-scrollbar-thumb {
		background-color: #fff;
	}

	.content-chat {
		height: calc(100% - 120px);
		padding: 5px;
		overflow-y: auto;
		// display: flex;
		flex-direction: column;
		justify-content: flex-end;

		.left-chat {
			background: #ffffffeb;
			min-height: 50px;
			max-width: 300px;
			border-radius: 5px;
			display: flex;
			flex-direction: column;
			padding: 5px;
			margin-top: 5px;

			span {
				color: black;
				word-break: break-word;
			}
		}

		.right-chat {
			background: #007bff7d;
			min-height: 50px;
			max-width: 300px;
			border-radius: 5px;
			display: flex;
			flex-direction: column;
			padding: 5px;
			margin-right: 0;
			margin-left: auto;
			margin-top: 5px;

			span {
				color: #fff;
				word-break: break-word;
			}
		}
	}

	.send-chat {
		height: 70px;
		border-radius: 10px;
		background: #ffffff2e;
		display: flex;
		justify-content: center;
		align-items: center;

		input.custom-input {
			flex: 0 0 80%;
			height: 40px;
			border-radius: 5px;
			color: #fff;
			outline: none;
		}

		.button-send-group {
			flex: 0 0 15%;
			display: flex;
			justify-content: center;
			align-items: center;

			.send-button {
				background: #fff3f342;
				border: 1px solid #fff;
				border-radius: 50%;
				padding: 0;
				width: 40px;
				height: 40px;
				margin: 0;
				outline: none;
				font-size: 25px;
				display: flex;
				justify-content: center;
				align-items: center;
				color: #fff;
			}
		}
	}
}

.card2 {
	background-color: #081B3C !important;
	border: 0px !important;
	border-radius: 8px !important;
}

.inputGroup {
	border: 1px solid $primaryColor;
	border-radius: 5px;
	padding: 5px;
	display: flex;
	overflow: hidden;

	input {
		border: none;
		background-color: transparent;
		color: white;
		width: calc(100% - 80px);
	}

	button {
		width: 80px;
		border-radius: 5px;
	}

	input:active,
	input:focus {
		border: none;
		outline: none;
	}
}

.card2 {
	background-color: rgba(0, 0, 0, 0.3);
	margin: 0 2px;
	border: 1px solid #00ff85;
	width: 100%;
	position: relative;
	margin: auto;
	border-radius: 15px !important;
}
</style>

<style lang="scss">
.btn.btn-aktrade{
	background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%) !important;
}
</style>