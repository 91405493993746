<template>
  <div class="privateAgency">
    <!-- MAIN PART -->
    <div class="main" style="padding-left: 20px; padding-right: 20px">
      <b-row>
        <b-col class="right" cols="12" md="5" v-if="!showdetail">
          <div class="card2">
            <div class="form-group">
              <label for="">{{ $t('support.subject') }}</label>
              <select
                class="form-control custom-input"
                v-model="ticket.subject"
                style="height: 45px"
              >
                <option
                  :value="list.ticket_subject_id"
                  v-for="list in ListSubject"
                  :key="list.id"
                >
                  {{
                    $t(
                      `ticket.${list.ticket_subject_name
                        .toLowerCase()
                        .split(' ')
                        .join('_')}`
                    )
                  }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="">{{ $t('support.content') }} (*)</label>
              <textarea
                rows="5"
                class="form-control custom-input"
                :placeholder="$t('support.description_your_problems')"
                v-model="ticket.content"
              ></textarea>
            </div>

            <div class="form-group">
              <button class="btnNoStyle btn-send" @click="sendTicket">
                {{ $t('support.send') }}
              </button>
            </div>
          </div>
        </b-col>
        <b-col v-else class="mx-auto" cols="12" md="5">
          <DetailTicket
            :ID="ticketDetails.id"
            :Subject="ticketDetails.subject"
            :SubjectID="ticketDetails.SubjectID"
          />
        </b-col>
        <b-col class="right" cols="12" md="7">
          <div class="card2">
            <div class="table-responsive historyTable">
              <b-table
                :items="ListTicket"
                :fields="fields"
                show-empty
                class="myTable"
                v-if="!showTableMobile"
              >
                <template #empty="">
                  <h5 class="text-center p-2">
                    {{ $t('support.no_data_available_in_table') }}
                  </h5>
                </template>
                <template #cell(action)="data">
                  <b-button
                    class="btnNoStyle btn-details"
                    @click="
                      showDetail(
                        data.item.ticket_ID,
                        data.item.ticket_subject_name,
                        data.item.ticket_subject_id
                      )
                    "
                    >{{ $t('ticket.detail') }}</b-button
                  >
                </template>
              </b-table>
              <div class="table-mobile" v-else>
                <h3 style="font-size: 16px">{{ $t('support.listTicket') }}</h3>
                <template v-if="ListTicket && ListTicket.length > 0">
                  <div
                    v-for="(ticket, idx) in ListTicket"
                    :key="'followHistory: ' + ticket.ticket_ID"
                    class="w-100"
                    role="tablist"
                  >
                    <b-button
                      v-b-toggle="`profitHistory-${idx}`"
                      class="btnNoStyle header"
                      role="tab"
                      >{{ ticket.ticket_subject_name }}</b-button
                    >
                    <b-collapse
                      :id="`profitHistory-${idx}`"
                      class="mt-2"
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <p>- {{ $t('support.ticket_id') }}: {{ ticket.ticket_ID }}</p>
                      <p>- {{ $t('support.subject') }}: {{ ticket.ticket_subject_name }}</p>
                      <p>- {{ $t('support.status') }}: {{ ticket.ticket_Status }}</p>
                      <p>- {{ $t('support.datetime') }}: {{ ticket.ticket_Time }}</p>
                      <p>
                        - {{ $t('support.action') }}:
                        <b-button
                          class="btnNoStyle btn-details w-50"
                          @click="
                            showDetail(
                              ticket.ticket_ID,
                              ticket.ticket_subject_name,
                              ticket.ticket_subject_id
                            )
                          "
                          >{{ $t('ticket.detail') }}</b-button
                        >
                      </p>
                    </b-collapse>
                  </div>
                </template>
                <p class="text-center py-2 mb-0" v-else>
                  {{ $t('support.no_data_available_in_table') }}
                </p>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import DetailTicket from '@/components/Ticket/DetailTicket2.vue';

export default {
  name: 'SupportViewPage',
  components: {
    DetailTicket,
  },
  data() {
    return {
      inviteCode: '32A1265',
      ticket: {
        content: '',
        subject: 1,
        token: '',
      },
      ticketDetails: {
        id: null,
        subject: null,
        SubjectID: null,
      },
      showdetail: false,
      widthL: 0,
      showTableMobile: false,
    };
  },
  computed: {
    registerLink() {
      return `https://wefinex.net/reg?r=${this.inviteCode}`;
    },
    ...mapGetters({
      ListSubject: 'ticket/ListSubject',
      ListTicket: 'ticket/ListTicket',
    }),

    fields() {
      return [
        {
          key: 'ticket_ID',
          label: this.$t('support.ticket_id'),
          class: 'text-center',
        },
        {
          key: 'ticket_subject_name',
          label: this.$t('support.subject'),
          class: 'text-center',
        },
        {
          key: 'ticket_Status',
          label: this.$t('support.status'),
          class: 'text-center',
        },
        {
          key: 'ticket_Time',
          label: this.$t('support.datetime'),
          class: 'text-center',
        },
        {
          key: 'action',
          label: this.$t('support.action'),
          class: 'text-center',
        },
      ];
    },
  },
  methods: {
    ...mapMutations(['onload', 'outload']),
    sendTicket: async function () {
      await this.$store.dispatch('ticket/req_postSubject', this.ticket);
      this.ticket = {
        content: '',
        subject: 1,
        token: '',
      };
    },
    showDetail(id, subject, SubjectID) {
      this.ticketDetails.id = id;
      this.ticketDetails.subject = subject;
      this.ticketDetails.SubjectID = SubjectID;
      if (id === undefined || id === null) {
        this.$toastr.e(
          'Please Reload Page And Chose Ticket Detail Again',
          'Something Wrong'
        );
        return;
      } else {
        this.showdetail = false;
        this.$nextTick(() => {
          this.showdetail = true;
        });
      }
    },
    checkResizeWindow() {
      this.widthL = window.innerWidth;
    },
  },
  created() {
    this.$store.dispatch('ticket/req_getSubject');
    this.$store.dispatch('ticket/req_getTicket');

    let self = this;
    this.unsubscribe = this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case 'core/HiddenChat':
          self.ticketDetails = {
            id: null,
            subject: null,
            SubjectID: null,
          };
          self.showdetail = false;
          break;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  async mounted() {
    // simulate load data
    this.onload();
    await new Promise((resolve) => setTimeout(resolve, 300));
    this.outload();
    this.checkResizeWindow();
    this.windowEvent = () => {
      this.checkResizeWindow();
    };
    if (this.widthL <= 576) {
      // console.log(this.widthL);
      this.showTableMobile = true;
    } else {
      this.showTableMobile = false;
    }
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/colors.scss';
@import '~@/assets/scss/mixins.scss';

.privateAgency {
  // background-image: url("~@/assets/images/background/bg.jpg");
  // background-size: cover;
  // background-position: center center;
  // background-repeat: no-repeat;
  // background-attachment: fixed;
  min-height: 100vh;
}

.table thead th {
  vertical-align: middle;
  // border-bottom: 1px solid $primaryColor;
  // border-top: 1px solid $primaryColor;
}

.main {
  min-height: calc(100vh - 100px);
  padding: 50px 0;

  .left {
    margin-bottom: 30px;

    .title {
      font-size: 2em;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .button {
      font-size: 1.5em;
      font-weight: bold;
    }
  }

  .right {
    .card2 {
      background: #081b3c;
      border-radius: 8px;
      padding: 30px 20px;
      @media (max-width: 576px) {
        padding: 20px;
      }

      label {
        color: #a8a6a6;
      }

      .btn-send {
        margin-top: 30px;
        width: 100%;
        height: 50px;
        background: linear-gradient(
          91.46deg,
          #0ec5ff 1.08%,
          #3964fb 83%
        ) !important;
        border-radius: 3px;
        position: relative;
        z-index: 1;
        font-weight: bold;
        font-size: 20px;
        color: #ffffff;

        @media (max-width: 1500px) {
          font-size: 16px;
        }

        @media (max-width: 576px) {
          font-size: 16px;
        }

        &::before {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          width: 100%;
          height: 50px;
          background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
          filter: blur(21px);
          z-index: -1;
        }
      }

      .btn-details {
        width: 100%;
        height: 35px;
        background: linear-gradient(
          91.46deg,
          #0ec5ff 1.08%,
          #3964fb 83%
        ) !important;
        border-radius: 3px;
        position: relative;
        z-index: 1;
        font-weight: bold;
        font-size: 16px;
        color: #ffffff;
      }
      .table-mobile {
        width: 100%;
        background: linear-gradient(
          180deg,
          #081e43 0%,
          rgba(1, 6, 15, 0.63) 100%
        );
        border-radius: 4px;
        h3 {
          margin-bottom: 20px;
        }

        .header {
          width: 100%;
          background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
          border-radius: 6px;
          padding: 0px 25px;
          height: 55px;
          margin-bottom: 20px;
        }

        .collapse {
          margin-top: -20px !important;
          padding: 20px 10px;
          box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.25);
          -webkit-backdrop-filter: blur(34px);
          backdrop-filter: blur(34px);
          border-radius: 7px;
          margin-bottom: 20px;

          P {
            margin-bottom: 0px;
          }
        }
      }
    }

    .inputGroup {
      border: 1px solid $primaryColor;
      border-radius: 5px;
      padding: 5px;
      display: flex;
      overflow: hidden;

      input {
        border: none;
        background-color: transparent;
        color: white;
        width: calc(100% - 80px);
      }

      button {
        width: 80px;
        border-radius: 5px;
      }

      input:active,
      input:focus {
        border: none;
        outline: none;
      }
    }
  }
}

.footer {
  background-color: #2b2f42;
  padding: 20px 0;

  .box {
    margin: 20px 0;

    .icon {
      border: 1px solid $primaryColor;
      width: 60px;
      height: 60px;
      padding: 10px;
      border-radius: 30px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .info {
      width: calc(100% - 80px);
      margin-left: 10px;

      .name {
        font-size: 1.1em;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
}

/*** RESPONSIVE ON PC ***/
@media (min-width: 992px) {
  .main {
    .left {
      .title {
        font-size: 2.5em;
      }
    }
  }
}
</style>